<template>
    <div class="main-page-container">
        <div class="main-page-content">
            <h1>Principles of Influence ⚖️</h1>
            <br>
            
            <div>
                <h2>Table of Contents</h2>
                <ol>
                    <li>
                        <a href="#scarcity">Scarcity</a>
                    </li>
                    <li>
                        <a href="#consistency">Consistency</a>
                    </li>
                    <li>
                        <a href="#authority">Authority</a>
                    </li>
                    <li>
                        <a href="#reciprocity">Reciprocity</a>
                    </li>
                    <li>
                        <a href="#likability">Likability</a>
                    </li>
                    <li>
                        <a href="#unity">Unity</a>
                    </li>
                    <li>
                        <a href="#social_proof">Social Proof</a>
                    </li>
                    <li>
                        <a href="#mnemonic_device">Mnemonic Device</a>
                    </li>
                </ol>
            </div>
            <br>

            <div>
                <div>
                    <h2>
                        <a id="scarcity">Scarcity</a>
                    </h2>
                    <p>
                        Fear of loss or the fear of loss of opportunity is the most motivating factor in human
                        psychology. Scarcity is more powerful than urgency.
                    </p>
                </div>
                <br>

                <div>
                    <h2>
                        <a id="consistency">Consistency</a>
                    </h2>
                    <p>
                        People want to remain consistent in what they do and say.
                    </p>
                </div>
                <br>

                <div>
                    <h2>
                        <a id="likability">Likability</a>
                    </h2>
                    <p>
                        People like people who like them (praise). People like people who are like them
                        (similarities).
                    </p>
                </div>
                <br>

                <div>
                    <h2>
                        <a id="reciprocity">Reciprocity</a>
                    </h2>
                    <p>
                        People feel obligated to give back.
                    </p>
                </div>
                <br>

                <div>
                    <h2>
                        <a id="authority">Authority</a>
                    </h2>
                    <p>
                        People are much more influenced by those that have a deep understanding and a lot of
                        experience in their craft.
                    </p>
                </div>
                <br>

                <div>
                    <h2>

                        <a id="social_proof">Social Proof</a>
                    </h2>
                    <p>
                        People are influenced by people who have credible people that vouch for them.
                    </p>
                </div>
                <br>

                <div>
                    <h2>
                        <a id="unity">Unity</a>
                    </h2>
                    <p>
                        People are influenced by people who have a shared identity or group membership ('We' group).
                    </p>
                </div>
                <br>

                <div>
                    <h2>
                        <a id="mnemonic_device">Mnemonic Device to Memorize the Principles</a>
                    </h2>
                    <p>
                        Here is a useful mnemonic device to memorize these principles. <br>
                        SCARLUS: Sally Can Always Run Laps Under the Sun. <br>
                        Scarcity, Consistency, Authority, Reciprocity, Likability, Likaability, Unity, Social
                        Proof.
                    </p>
                </div>
                <br>
            </div>

        </div>
        <br>
        <br>
        <section class='comments' aria-labelledby="comment">
            <h2 id="comment">Comments</h2>
            <Disqus />
        </section>
    </div>
</template>

<script>
export default {
    name: 'InfluencePage',
    props: {}
}
</script>

<style></style>