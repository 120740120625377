<template>
    <div class="main-page-container">
        <div class="main-page-content">
            <h1>Schemas 📂</h1>
            <p>
                Schemas represent our understanding and knowledge of a particular concept or category. They are
                organized
                sets of information that help us understand and remember new information by relating it to what we
                already
                know. Schemas allow us to interpret and make sense of new experiences by organizing them into familiar
                patterns and categories.
            </p>
        </div>
        <section class='comments' aria-labelledby="comment">
            <h2 id="comment">Comments</h2>
            <Disqus />
        </section>
    </div>

</template>

<script>
export default {
    name: 'MentalModelsPage',
    props: {}
}
</script>

<style scoped>

</style>