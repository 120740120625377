<template>
    <div class="main-page-container">
        <div class="main-page-content">
            <div>
                <h1>Data Manipulation Languages</h1>
            </div>
            <br>

            <div>
                <ol>
                </ol>
            </div>
            <br>

        </div>
        <br>
        <br>

        <section class='comments' aria-labelledby="comment">
            <h2 id="comment">Comments</h2>
            <Disqus />
        </section>
    </div>
</template>

<script>
export default {
    name: 'DataManipulationLanguages',
    props: {}
}
</script>

<style scoped></style>