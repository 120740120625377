<template>
    <div class="main-page-container">
        <div class="main-page-content">
            <h1>Decision Making 🧑‍⚖️</h1>
            <br>
            <ol>
                <li>
                    Heuristics & Biases
                </li>
            </ol>
        </div>
        <section class='comments' aria-labelledby="comment">
            <h2 id="comment">Comments</h2>
            <Disqus />
        </section>
    </div>
</template>

<script>
export default {
    name: 'DecisionMakingPage',
    props: {}
}
</script>

<style scoped></style>