<template>
    <div class="main-page-container">
        <div class="main-page-content">
            <h1>Data Structures 📚</h1>
            <p>
                Ways of organizing and storing data so they can be accessed and worked on efficiently in a computer.
            </p>
        </div>
        <section class='comments' aria-labelledby="comment">
            <h2 id="comment">Comments</h2>
            <Disqus />
        </section>
    </div>
</template>

<script>
export default {
    name: 'DataStructuresPage',
    props: {}
}
</script>

<style scoped></style>